import VuePdfApp from 'vue-pdf-app'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/fontAwesome'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
// import './assets/icons/main.css'
import api from './services/api'
import msal, { msalMixin } from 'vue-msal'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
  Vue,
  dsn: 'https://4e14de5bb0397f3cc7500fc3294ba8a6@o4508943312355328.ingest.de.sentry.io/4508943315435600',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'katalogwand.meinvme.de', /^https:\/\/katalogwand\.meinvme\.de/, 'katalogwand-staging.meinvmeint.de', /^https:\/\/katalogwand-staging\.meinvmeint\.de/, /^\//]
    })
  ],
  tracesSampleRate: 1.0
})

Vue.component('vue-pdf-app', VuePdfApp)
const domain = window.location.origin
Vue.use(PerfectScrollbar)
Vue.config.productionTip = false
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0)
Vue.use(require('vue-moment'))
/*

Katalogwand-Gesellschafter-Einkauf 25fae66c-d81b-4fe8-9567-2635d85425e4
Katalogwand-Gesellschafter-Admin 30cd8fd3-303e-4e8d-8acd-69066e29a96d
Katalogwand-VME-Admin 6d05b4fa-4890-4c25-8b23-be641fdfc951
Katalogwand-Gesellschafter-Verkauf 7a2d9f05-e6f8-4786-a984-2bd4a359f111

*/
/* const groups = [
  {
    id: '25fae66c-d81b-4fe8-9567-2635d85425e4',
    name: 'seller'
  },
  {
    id: '7a2d9f05-e6f8-4786-a984-2bd4a359f111',
    name: 'purchaser'
  },
  {
    id: '6d05b4fa-4890-4c25-8b23-be641fdfc951',
    name: 'vmeAdmin'
  },
  {
    id: '30cd8fd3-303e-4e8d-8acd-69066e29a96d',
    name: 'firmenAdmin'
  }
] */

Vue.use(msal, {
  auth: {
    autoRefreshToken: true,
    clientId: config.VUE_APP_MSAD_CLIENT_ID || process.env.VUE_APP_MSAD_CLIENT_ID,
    authority: config.VUE_APP_MSAD_AUTHORITY || process.env.VUE_APP_MSAD_AUTHORITY,
    redirectUri: domain,
    postLogoutRedirectUri: domain,
    navigateToLoginRequestUrl: false,
    framework: {
      globalMixin: true
    },
    requireAuthOnInitialize: true,
    onAuthentication: (ctx, error, response) => {
      console.info('----onAuth-----\n', response)
    },
    onToken: async (ctx, error, response) => {
      if (response.accessToken) {
        localStorage.setItem('token', response.accessToken)
        store.commit('SET_MS_ACCESSTOKEN', response.accessToken)
      }
    }
  },
  cache: {
    cacheLocation: 'localStorage', // Use localStorage instead of cookies
    storeAuthStateInCookie: false // Disable additional cookie storage
  }
})
Vue.prototype.$api = api
new Vue({
  store,
  router,
  mixins: [msalMixin],
  beforeCreate: function () {
    // set authentication token in axios header
  },
  render: (h) => h(App)
}).$mount('#app')
